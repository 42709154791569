import React, { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import './App.css';

interface SongInfo {
    current: {
        name: string;
        starts: string;
        ends: string;
    };
    next: {
        name: string;
        starts: string;
        ends: string;
    };
}

function formatTime(timeString: string): string {
    const date = new Date(timeString);
    date.setHours(date.getHours() + 1); // Add one hour
    let hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // If hours is 24, set it to 00
    if (hours === '24') {
        hours = '00';
    }

    return hours + ':' + minutes;
}



function Clock() {
    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="clock">
            {time.toLocaleTimeString([], { hour12: false })}
        </div>
    );
}


function App() {
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [showContainers, setShowContainers] = useState(false);
    const [currentSong, setCurrentSong] = useState<SongInfo | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get<SongInfo>('https://libretimetikka-8b513026764d.herokuapp.com/api/live-info');
                console.log('API Response:', response.data); // Log the response data
                setCurrentSong(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const togglePlay = (play?: boolean) => {
        if (audioRef.current) {
            if (typeof play !== 'undefined') {
                if (play) {
                    audioRef.current.play();
                } else {
                    audioRef.current.pause();
                }
                setIsPlaying(play);
            } else {
                if (isPlaying) {
                    audioRef.current.pause();
                } else {
                    audioRef.current.play();
                }
                setIsPlaying(!isPlaying);
            }
        }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Space') {
            event.preventDefault(); // Prevent the default spacebar behavior (e.g., scrolling)
            togglePlay();
            setShowContainers(prevState => !prevState);
        }
    };

    const handleClick = () => {
        togglePlay();
        setShowContainers(prevState => !prevState);
    };

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [togglePlay]);

    return (
        <div className="App">
            <header className="App-header">
                <div className="message-1">New Website Under Construction</div>
                <div className="player" onClick={handleClick}>
                    <img src="/tikkalogowhite.png" alt="Radio" className="radio-image" />
                    {currentSong && currentSong.current && showContainers && (
                        <div>
                            {/* <Clock />  Add Clock component here */}
                            <div className="now-container">
                                <div className="now-starts">{formatTime(currentSong.current.starts)}</div>
                                <div className="now-field">
                                    <div className="now-name">
                                        {currentSong.current.name.substring(2)}
                                    </div>
                                </div>
                                <div className="now-ends">{formatTime(currentSong.current.ends)}</div>
                            </div>
                            <div className="next-container">
                                <div className="next-starts">{formatTime(currentSong.next.starts)}</div>
                                <div className="next-field">
                                    <div className="next-name">
                                        {currentSong.next.name.substring(2)}
                                    </div>
                                </div>
                                <div className="next-ends">{formatTime(currentSong.next.ends)}</div>
                            </div>
                        </div>
                    )}
                    {!showContainers && (
                        <div className="message-2">Press Space Bar or Click to Play and Pause the Player</div>
                    )}
                    <audio ref={audioRef} src="https://libretimetikka-8b513026764d.herokuapp.com/stream" />
                </div>
            </header>
        </div>
    );
}

export default App;